export const UAT_DEVICE_DATA = {
  UniqueDeviceId: "00000999",
  PaymentDeviceIP: "0.0.0.0",
  MachineName: "KIOSK-999",
};

const CARD_EXPIRED = "Card has expired";
const INSUFFICIENT_FUNDS = "Insufficient funds";

export const PAYMENT_ERRORS = {
  54: CARD_EXPIRED,
  101: CARD_EXPIRED,
  51: INSUFFICIENT_FUNDS,
};
